import { store } from 'react-easy-state'

const userData = store({
  loggedIn: false,
  id: null,
  firstname: null,
  lastname: null,
  permission_level: null,
  email: null,
  token: null,
  triedlogins: null
})

export default userData