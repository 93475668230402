import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
import cookie from 'react-cookies'
import UserClass from 'custom_classes/User'
import { fetchAPI } from 'custom_classes/UserManagement'
import LinearProgress from '@material-ui/core/LinearProgress'
import GlobalLoading from 'easy-states/GlobalLoading'
import { view } from 'react-easy-state'



// = styles =
// 3rd
import 'styles/bootstrap/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';

import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import grayTheme from './themes/grayTheme';

let MainApp = loadable({
  loader: () => import('components/Layout/AppLayout/MainApp'),
  loading: LoadingComponent
})
let Account = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})


class App extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      token: UserClass.token
    };
  }
  componentDidMount() {}

  render() {
    const { match, location, layoutBoxed, navCollapsed, navBehind, fixedHeader, sidebarWidth, theme } = this.props;
    let materialUITheme;
    switch (theme) {
      case 'gray':
        materialUITheme = grayTheme;
        break;
      case 'dark':
        materialUITheme = darkTheme;
        break;
      case 'light':
        materialUITheme = lightTheme;
        break;
      default:
        materialUITheme = darkTheme;
    }

    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      return ( <Redirect to={'/user/login'}/> );
    }
    
    if(UserClass.loggedIn === false && this.props.location.pathname.substring(0,5) !== "/user") {
      if(cookie.load("token") === undefined) {
        return ( <Redirect to={'/user/login'}/> );
      } else {
        UserClass.token = cookie.load("token")

        let result = fetchAPI("/user/check_token", "GET", this.props.history, {}, 2)

        .then(response => {
          if(response[0]) {
            response = response[1];

            UserClass.loggedIn = true
            UserClass.id = response["user"]["usr_id"]
            UserClass.firstname = response["user"]["firstname"]
            UserClass.lastname = response["user"]["lastname"]
            UserClass.email = response["user"]["email"]
            UserClass.permission_level = response["user"]["permission_level"]
            UserClass.triedlogins = response["user"]["triedlogins"]
          } else {
            return ( <Redirect to={'/user/login'}/> );
          }
        })
      }
    }

    return (
      <MuiThemeProvider theme={materialUITheme}>
        <div id="app-inner">
          <div className="preloaderbar hide"><span className="bar" /></div>
          
          { GlobalLoading.loading && (
          <div className="globalLoader-background" style={{
            background: "rgba(0,0,0,0.4)",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div className="globalLoader" style={{
              position: "fixed",
              width: "400px",
              padding: "20px",
              left: "50%",
              marginLeft: "-200px",
              zIndex: 100000,
              background: "#343A40",

            }}>
              <LinearProgress />
              { GlobalLoading.message !== "" && (
                <React.Fragment>
                  <br />

                  <p style={{
                    color: "#fff",
                    textAlign: "center",
                    margin: 0
                  }}>{GlobalLoading.message}</p>
                </React.Fragment>
              )}
            </div>
          </div>
          )}

          <div
            className={classnames('app-main h-100', {
              'fixed-header': fixedHeader,
              'nav-collapsed': navCollapsed,
              'nav-behind': navBehind,
              'layout-boxed': layoutBoxed,
              'theme-gray': theme === 'gray',
              'theme-dark': theme === 'dark',
              'sidebar-sm': sidebarWidth === 'small',
              'sidebar-lg': sidebarWidth === 'large'})
          }>
            <Route path={`${match.url}app`} component={MainApp} />
            <Route path={`${match.url}user`} component={Account} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
});

export default connect(
  mapStateToProps
)(view(App));
