const messages = {
  "invalid_password": "Das eingegebene Passwort ist inkorrekt.",

  // request_password
  "user_not_found": "Die angegebene E-Mail Adresse wurde nicht gefunden.",
  "smtp_error": "Der Mailserver ist leider nicht erreichbar.",
  "empty_or_invalid_email": "Es wurde eine leere oder ungültige E-Mail übergeben.",

  "email_exists": "Die angegebene E-Mail Adresse wird bereits verwendet.",
  "invalid_old_password": "Das eingegebene aktuelle Passwort ist inkorrekt.",
}

export default messages