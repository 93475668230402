import { store } from 'react-easy-state'

const GlobalLoading = store({
  loading: false,
  message: "",
  _loads: 0,

  startLoading: (msg = null) => {
    GlobalLoading._loads++
    GlobalLoading.loading = true

    if(msg !== null) {
      GlobalLoading.message = msg
    }
  },
  endLoading: () => {
    GlobalLoading._loads--
    if(GlobalLoading._loads === 0) {
      GlobalLoading.loading = false
    }
  }
})

export default GlobalLoading