import UserClass from 'custom_classes/User'
import cookie from 'react-cookies'
import queryString from 'query-string'
import APPCONFIG from 'constants/appConfig'
import ERROR from 'custom_classes/ErrorMessages_deDE.js'

export async function fetchAPI (endpoint, method, history, data = {}, version = 1) {
  let request = {
    method: method,
    cache: 'no-cache',
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    }
  }

  if(endpoint !== "/user/login" && endpoint !== "/user/forgot-password" && UserClass.token !== null)
    request["headers"]["Authorization"] = 'Bearer ' + UserClass.token

  if(method === "POST") 
    request["body"] = queryString.stringify(data, {arrayFormat: 'bracket'})

  let response
    if (version === 1) {
    response = await fetch(APPCONFIG.apiEndpoint + endpoint, request)
  } else {
    response = await fetch(APPCONFIG.apiV2Endpoint + endpoint, request)
  }

  response = await response.json()

  if(response["status"]["code"] === 200) {
    return [true, response["result"]]
  } else {
    if(response["status"]["code"] === 403 && response["status"]["message"] === "invalid_token") {
      alert("Ungültiger Token. Weiterleitung zum Login!")
      history.push('/user/login')
      return [false, response["status"]]
    } else {
      return [false, response["status"]]
    }
  }
}

export function errorHandler (keys = "") {
  let errors = keys.split(","),
      output = ""
      
  for(let errorKey in errors) {
    let errorMsg = errors[errorKey]

    if(errorMsg in ERROR) {
      output += ERROR[errorMsg] + " "
    } else {
      output += errorMsg + " "
    }
  }

  return output
}

export function isValidEmail(email) {
  var re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return re.test(email);
}

export function structureParentsChildObject(input) {
  var map = {};
  for(var i = 0; i < Object.keys(input).length; i++){
      var obj = input[i];
      obj.childs= [];

      map[obj.CHA_ID] = obj;

      var parent = obj.CHA_PARENT_ID || '-';
      if(!map[parent]){
          map[parent] = {
              childs: []
          };
      }
      map[parent].childs.push(obj);
  }

  return map['-'].childs;

}

export function structureParentsChildArray(input) {
  var map = {};
  input.forEach(obj => {
      obj.childs= [];

      map[obj.CHA_ID] = obj;

      var parent = obj.CHA_PARENT_ID || '-';
      if(!map[parent]){
          map[parent] = {
              childs: []
          };
      }
      map[parent].childs.push(obj);
  })

  return map['-'].childs;

}